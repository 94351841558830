.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.flex {
    display: flex;
}

.heroImage {
  width: 100%;
}
.heroImage img {
    width: 100%;
}

.aboutUs {
  background: linear-gradient(180deg, #000000, #454545, #000000);
  color: white;
}

.aboutUs h1 {
    text-align: left;
    margin: 30px;
    font-family: 'Oswald', sans-serif;
}

.aboutUs ul {
    margin-left: 100px;
}

.aboutUs li {
    text-align: left;
    font-size: 25px;
    margin: 15px;
}

.missionWrapper {
    margin-top: 50px;
}

.mission {
    background-color: #FFA559;
}

.mission h1 {
    color: #000000;
}

.mission ul {
    margin: 50px;
}

.mission li {
    color: #000000;
}

.contactCall {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 20px;
    padding-block: 150px;
    background: linear-gradient(180deg, #000000, #454545);
}

.contactCall h1 {
    margin: 0;
}

.contactCall a {
    color: white;
}

@media screen and (max-width: 1400px) {
    .missionWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }   
    .missionWrapper img {
        width: 550px;
        height: auto;
        margin: 10px;
        border-radius: 15px;
    }
}

@media screen and (max-width: 1100px) {
    .aboutUs h1 {
        font-size: 25px;
    }   
    .aboutUs li {
        font-size: 20px;
    }   
    .contactCall a {
        font-size: 20px;
    }
}

@media screen and (max-width: 590px) {
    .missionWrapper img {
        width: 400px;
    }
    .aboutUs h1 {
        font-size: 20px;
    }   
    .aboutUs li {
        font-size: 15px;
    }
    .aboutUs ul {
        margin-left: 50px;
    }
    .contactCall a {
        font-size: 15px;
    }
}

@media screen and (max-width: 415px) {
    .missionWrapper img {
        width: 300px;
    }
    .aboutUs h1 {
        font-size: 17px;
    }   
    .aboutUs li {
        font-size: 13px;
    }
    .contactCall a {
        font-size: 13px;
    }
}

@media screen and (max-width: 340px) {
    .missionWrapper img {
        width: 250px;
    }
    .aboutUs ul {
        margin: 15px;
    }   
}