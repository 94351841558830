.table {
  font-family: "Open Sans", sans-serif;
  color: white;
  table-layout: fixed;
  width: 100%;
  background: linear-gradient(0deg, #242424, #000000);
  margin-bottom: 100px;
}

.table td,
th {
  text-align: left;
  border: 1px solid #e5e5e5;
  padding: 8px 10px;
}

.table tr:hover {
  border: 1px solid #ffa559;
  color: #ffa559;
  cursor: pointer;
}

.table thead th {
  background: linear-gradient(0deg, #454545, 80%, #000000);
  font-family: "Oswald", sans-serif;
  font-size: large;
  border: none;
  border-bottom: 2px solid white;
  padding-block: 15px;
}

.table thead tr:hover {
  scale: none;
  color: white;
}

.selectedRow {
  scale: 1.02;
  color: #ffa559;
}

@keyframes growRow {
  0% {
    font-size: 0px;
    height: 0px;
    margin: 0;
  }
  25% {
    height: 3.33px;
  }
  50% {
    height: 6.66px;
  }
  100% {
    height: 10px;
  }
}

.expansionRow {
  scale: 0.98;
  color: #ffa559;
  background-color: #454545;
}

.expansionRow td {
  border: none;
  animation-name: growRow;
  animation-duration: 0.25s;
}

.expansionRow tr td:hover {
  scale: none;
}

.expansionRowCustomInputs {
  scale: 0.98;
  background-color: #181818;
}

.expansionRowCustomInputs td {
  border: none;
  color: #ffa559;
  animation-name: growRow;
  animation-duration: 0.25s;
}
