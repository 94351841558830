.button {
    font-family: 'Oswald', sans-serif;
    font-weight: 100;
    color: white;
    font-size: 25px;
    min-width: fit-content;
    min-height: fit-content;
    width: 12rem;
    border-radius: 5px;
    padding: 10px;
    border: none;
    padding-inline: 25px;
}

.button:hover {
  scale: 1.1;
  font-weight: 400;
}

@media screen and (max-width: 1400px) {
   .button {
    font-size: 1.1rem;
   }
  }

@media screen and (max-width: 1200px) {
   .button {
    font-size: 1rem;
   }
  }
  
  @media screen and (max-width: 530px) {
    .button {
        scale: 0.85;
    }
  }
  
  @media screen and (max-width: 320px) {
   .button {
    scale: 0.75;
   }
  }

  @media screen and (max-width: 280px) {
   .button {
    scale: 0.65;
   }
  }