.listWrapper {
  position: relative;
  margin-bottom: 50px;
}
.list {
  display: grid;
  grid-auto-flow: column;
  overflow: auto;
  text-align: center;
  margin-bottom: 50px;
}

.flex {
  display: flex;
  width: 100%;
}

.centerAlign {
  justify-content: center;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.list {
  scrollbar-width: thin;
  scrollbar-color: #000000 #8f8f8f;
}

/* Chrome, Edge, and Safari */
.list::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.list::-webkit-scrollbar-track {
  background-color: transparent;
}

.list::-webkit-scrollbar-thumb {
  background-color: #454545;
  border-radius: 25px;
  border: 1px solid #747474;
}

.chevron {
  position: absolute;
  width: fit-content;
  filter: drop-shadow(5px 5px 5px #454545);
}

.chevron:hover {
  scale: 1.2;
}

.chevron:active {
  color: #ffa559;
}

.chevronLeft {
  left: 0px;
  color: white;
}
.chevronRight {
  right: 0px;
  color: white;
}

.loadingDiv {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1150px) {
  .chevron {
    display: none;
  }
}
