.card {
  background: linear-gradient(180deg, #2c2c2c, #454545, #2c2c2c);
  height: 215px;
  aspect-ratio: 0.65;
  border: solid 2px #ffa559;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 10px;
  color: white;
}

.card:hover {
  background: linear-gradient(180deg, #383838, #454545, #383838);
  scale: 1.02;
}

.card h1 {
  font-family: "Oswald", sans-serif;
  font-size: clamp(15px, 3vw, 1.5em);
  max-width: 85%;
}

.imgWrapper {
  height: 60%;
  width: 90%;
  margin-top: 1em;
  margin-inline: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.imgWrapper img {
  height: auto;
  width: 90%;
  max-width: 100%;
  border-radius: 10px;
}

.info {
  padding-inline: 1vw;
  text-align: center;
}

.info p {
  font-family: "Open Sans", sans-serif;
  font-weight: 100;
  font-size: clamp(11px, 3vw, 1em);
  color: white;
  text-align: center;
  margin: 2px;
}

@media screen and (min-width: 40em) {
  .card {
    height: 275px;
  }
}

@media screen and (min-width: 80em) {
  .card {
    height: 375px;
  }
}

@media screen and (max-width: 325px) {
  .card {
    margin: 1px;
  }
}
