.card {
  background: linear-gradient(180deg, #2c2c2c, #454545, #2c2c2c);
  aspect-ratio: 1.5;
  width: 200px;
  border: solid 2px;
  border-color: #ffa559;
  border-radius: 8px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card:hover {
  background: linear-gradient(180deg, #383838, #454545, #383838);
  scale: 1.02;
}

.imgWrapper {
  height: 90%;
  width: 90%;
  background-color: white;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgWrapper img {
  width: 90%;
  max-height: 90%;
  object-fit: contain;
}

@media screen and (min-width: 40em) {
  .card {
    width: 250px;
  }
}

@media screen and (min-width: 80em) {
  .card {
    width: 350px;
  }
}
