.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.heroImage {
  width: 100%;
}

.heroImage img {
  width: 100%;
}
.buttonGrid {
  display: grid;
  margin-block: 100px;
  width: 50%;
  grid-template-columns: auto auto;
  row-gap: 100px;
  column-gap: 100px;
  justify-items: center;
  align-items: center;
}

.buttonGrid button {
  height: 75px;
  width: 250px;
}

@media screen and (max-width: 1100px) {
  .buttonGrid {
    width: 70%;
  }
}

@media screen and (max-width: 845px) {
  .buttonGrid {
    width: 90%;
  }
}

@media screen and (max-width: 670px) {
  .buttonGrid {
    width: 75%;
    margin-block: 50px;
    column-gap: 10%;
    row-gap: 20%;
  }

  .buttonGrid button {
    width: 90%;
    padding-inline: 5px;
  }
}

@media screen and (max-width: 415px) {
    .buttonGrid {
        margin-block: 15px;
        column-gap: 2%;
        row-gap: 2%;
    }

    .buttonGrid button {
        width: 100%;
    }
}

@media screen and (max-width: 315px) {
    .buttonGrid {
        width: 98%;
        column-gap: 2px;
    }
}
