.menu {
  height: 100%;
  width: 0; /*Being set with useEffect and timer in NavMenu.jsx*/
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, #000000 15%, #454545, #000000);
  border-bottom-right-radius: 15px;
  border-style: solid;
  border-color: #454545;
  border-top: none;
  border-left: none;
  border-width: 0.25px;
  overflow-x: hidden;
  padding-top: 60px;
  transition: 0.25s;
}

.navList {
  display: flex;
  flex-direction: column;
  margin-block: 4rem;
  margin-inline: 2rem;
  position: relative;
  transition: 0.5s;
}

.nav {
  text-decoration: none;
  margin-block: 15px;
  text-align: left;
  color: white;
  font-size: 1.5rem;
  overflow-wrap: normal;
}

.nav:hover {
  color: #ffa559;
  scale: 1.03;
}

@media screen and (max-width: 690px) {
  .nav {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 570px) {
  .nav {
    font-size: 1rem;
  }
}

@media screen and (max-width: 570px) {
  /* .menu {
        height: 30%;
        width: 100%;
    }
    .navList {
        display: flex;

    } */
}

@media screen and (max-width: 280px) {
  .menu {
    width: 40%;
  }

  .navList {
    justify-content: center;
  }

  .nav {
    margin-block: 0.5rem;
  }
}
