.pageTitle {
    margin: 0;
}

.pageTitle h1 {
    font-family: 'Oswald', sans-serif;
    margin: 0;
    color: white;
    padding-top: 20px;
    padding-bottom: 50px;
}

@media screen and (max-width: 845px) {
    .pageTitle h1 {
        font-size: 25px;
    }
  }

  @media screen and (max-width: 580px) {  
    .pageTitle h1 {
        font-size: 20px;
    }
  }
  