.grid {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  color: white;
}
  
  @media screen and (min-width: 40em) {
    .grid {
      grid-template-columns: auto auto auto;
    }
  }

  @media screen and (min-width: 80em) {
    .grid {
      grid-template-columns: auto auto auto auto;
    }
  }
  