.description {
  padding: 50px;
}

.description h1 {
  text-align: left;
  font-family: "Oswald", sans-serif;
  font-size: 40px;
}

.description h2 {
  text-align: left;
  font-family: "Oswald", sans-serif;
  font-size: 35px;
}

.description p {
  text-align: left;
  font-family: "Open Sans", sans-serif;
  font-size: 30px;
}

.description li {
  text-align: left;
  font-family: "Open Sans", sans-serif;
  font-size: 30px;
}

.description a {
  text-align: left;
  font-family: "Open Sans", sans-serif;
  font-size: 30px;
  color: white;
}

.bgLinear {
  background: linear-gradient(180deg, #000000, #454545);
  color: white;
}

.bgOrange {
  background-color: #ffa559;
  color: #000000;
}

.info {
    display: flex;
    justify-content: space-around;
    text-align: left;
  color: white;
}

.info p {
  margin: 0;
}

.info img {
    margin: 20px;
}

.links {
    display: flex;
    justify-content: space-around;
    padding: 50px;
}

.centerAlign {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 1100px) {
    .description h1 {
        font-size: 30px;
    }
    .description p {
        font-size: 23px;
    }
    .description li {
        font-size: 23px;
    }
    .description a {
        font-size: 23px;
    }
    .info img {
        height: auto;
        width: 500px;
    }
    
}

@media screen and (max-width: 840px) {
    .description h1 {
        font-size: 25px;
    }
    .description p {
        font-size: 20px;
    }
    .description li {
        font-size: 20px;
    }
    .description a {
        font-size: 20px;
    }
    .info {
        flex-direction: column-reverse;
        align-items: center;
    }
    .info img {
        width: 400px;
    }
    .links {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-content: space-between;
        padding-block: 10px;
    }
    .links button {
        margin: 15px;
    }
}

@media screen and (max-width: 580px) {
    .description h1 {
        font-size: 20px;
    }
    .description p {
        font-size: 17px;
    }
    .description li {
        font-size: 17px;
    }
    .description a {
        font-size: 17px;
    }
    .info img {
        width: 350px;
    }
}

@media screen and (max-width: 400px) {
    .info img {
        width: 250px;
    }
}

@media screen and (max-width: 400px) {
    .info img {
        width: 200px;
    }
}