html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  body {
    scrollbar-width: thin;
    scrollbar-color: #000000 #8f8f8f;
  }

  /* Chrome, Edge, and Safari */
  body::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  body::-webkit-scrollbar-track {
    background-color: black;
  }

  body::-webkit-scrollbar-thumb {
    background-color: #454545;
    border-radius: 25px;
    border: 1px solid #747474;
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
