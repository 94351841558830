.heroImg {
  width: 100%;
}
.heroImg img {
  width: 100%;
}

.bgLinear {
  background: linear-gradient(180deg, #000000, #454545);
}
.bgLinearRev {
  background: linear-gradient(0deg, #000000, #454545);
}

.mission {
  color: white;
  padding-left: 50px;
}

.mission h2 {
  text-align: left;
  font-family: "Oswald", sans-serif;
  font-size: 35px;
}
.mission h3 {
  text-align: left;
  font-family: "Open Sans", sans-serif;
  font-weight: 100;
  font-size: 25px;
}
.mission ul {
  text-align: left;
  font-size: 20px;
  padding: 30px;
  margin: 0;
}
.mission li {
  padding: 10px;
}
.btnGrid {
  display: grid;
  min-height: 200px;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  align-items: center;
  justify-items: center;
  width: 95%;
}

.selMemberDescription {
  color: white;
  font-family: "Open Sans" sans-serif;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
}


.SMDContent {
  width: 90%;
  text-align: left;
  padding: 10px;
  padding-bottom: 100px;
  border: solid white 2px;
  border-radius: 12px;
}

.SMDContent h1 {
  margin: 0;
  padding-top: 20px;
  padding-bottom: 40px;
  font-family: "Oswald", sans-serif;
  font-size: 33px;
  text-decoration: underline;
}
.SMDContent p {
  margin: 0;
  padding-top: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 25px;
}
.SMDContent ul {
  margin: 0;
  padding-top: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 25px;
}
.SMDContent li {
  padding: 5px;
}

@media screen and (max-width: 1000px) {
  .mission h2 {
    font-size: 30px;
  }
  .mission h3 {
    font-size: 20px;
  }
  .mission ul {
    font-size: 18px;
  }
  .SMDContent h1 {
    font-size: 30px;
  }
  .SMDContent p {
    font-size: 18px;
  }
  .SMDContent ul {
    font-size: 18px;
  }
  .btnGrid {
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: minmax(80px, auto);
  }
  .btnGrid button {
    width: 35%;
  }
}

@media screen and (max-width: 800px) {
  .mission h2 {
    font-size: 25px;
  }
  .mission h3 {
    font-size: 18px;
  }
  .mission ul {
    font-size: 15px;
    padding: 10px;
  }
  .SMDContent h1 {
    font-size: 25px;
  }
  .SMDContent p {
    font-size: 15px;
  }
  .SMDContent ul {
    font-size: 15px;
  }
}

@media screen and (max-width: 650px) {
  .btnGrid {
    grid-auto-rows: minmax(60px, auto);
  }
  .btnGrid button {
    width: 70%;
  }
}

@media screen and (max-width: 440px) {
  .mission {
    padding: 10px;
  }
  .mission h2 {
    text-align: center;
    font-size: 20px;
  }
  .mission h3 {
    font-size: 16px;
  }
  .mission ul {
    font-size: 13px;
  }
  .SMDContent h1 {
    font-size: 20px;
  }
  .SMDContent p {
    font-size: 13px;
  }
  .SMDContent ul {
    font-size: 13px;
  }
}

@media screen and (max-width: 350px) {
  
  .btnGrid button {
    width: 95%;
  }
}