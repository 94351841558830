.loginContainer {
    display: flex;
    justify-content: center;
}

.login {
  width: 90%;
}

@media screen and (min-width: 40em) {
    .login {
        width: 70%;
    }
}

@media screen and (min-width: 80em) {
    .login {
        width: 50%;
    }
}