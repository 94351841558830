.home h1 {
  margin: 0;
  font-family: "Oswald", sans-serif;
}

.home h2 {
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

.home h3 {
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

.home li::marker {
  color: white;
}

.textAlign {
  text-align: left;
}

.alignCenter {
  align-items: center;
}

.topImage {
  width: 100%;
}

.flex {
  display: flex;
}

.spaceAround {
  justify-content: space-around;
}

.joinUs {
  background-color: #ffa559;
  min-height: fit-content;
  margin: 0;
  padding-inline: 50px;
  align-items: center;
}

.streetGraphic {
  height: 200px;
}

.adminAboutWrapper {
  width: 100%;
  justify-content: center;
}

.adminAbout {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  align-items: center;
  padding-block: 50px;
  background: linear-gradient(0deg, #454545 15%, #000000);
}

.adminAbout h2 {
  color: white;
  width: 40%;
  font-family: "Oswald", sans-serif;
}

.adminImage {
  margin: 20px;
  height: 300px;
  width: auto;
  border-radius: 7px;
}

.adminMessage {
  margin-inline: 100px;
}

.adminMessage h2,
p {
  color: white;
  text-align: left;
}

.lists {
  background: linear-gradient(0deg, #000000, #454545);
}

.flex {
  display: flex;
}

.centerAlign {
  justify-content: center;
}

@media screen and (max-width: 1200px) {
  .adminAbout h1 {
    font-size: 160%;
  }
  .adminAbout h2 {
    font-size: 160%;
    width: 80%;
  }
  .adminAbout h3 {
    font-size: 160%;
  }
  .lists h1 {
    font-size: 30px;
  }
  .textAlign {
    text-align: center;
  }

  .dashes {
    width: 10%;
  }

  .streetGraphic {
    height: 150px;
  }

  .adminAbout {
    flex-direction: column-reverse;
    align-content: center;
  }
  .centerAlign {
    justify-content: center;
  }
}

@media screen and (max-width: 1000px) {
  .streetGraphic {
    /* height: 225px; */
    margin-inline: 10px;
  }
}

@media screen and (max-width: 750px) {
  .adminAbout h1 {
    font-size: 130%;
  }
  .adminAbout h2 {
    font-size: 130%;
  }
  .adminAbout h3 {
    font-size: 130%;
  }
  .adminMessage {
    margin-inline: 50px;
  }
  .lists h1 {
    font-size: 25px;
  }
  .joinUs {
    padding-inline: 0;
    padding-right: 20px;
  }
  .streetGraphic {
    height: 100px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 540px) {
  .adminAbout h1 {
    font-size: 110%;
  }
  .adminAbout h2 {
    font-size: 110%;
  }
  .adminAbout h3 {
    font-size: 110%;
  }
  .adminImage {
    height: 250px;
  }
  .adminMessage p {
    font-size: 12px;
  }
  .joinUs h1 {
    font-size: 25px;
  }
  .lists h1 {
    font-size: 20px;
  }
  .streetGraphic {
    height: 75px;
  }
}

@media screen and (max-width: 430px) {

  .adminAbout h1 {
    font-size: 90%;
  }
  .adminAbout h2 {
    font-size: 90%;
  }
  .adminAbout h3 {
    font-size: 90%;
  }
  .adminImage {
    height: 210px;
  }
  .adminMessage {
    margin-inline: 10px;
  }
  .adminMessage p {
    font-size: 10px;
  }
  .joinUs h1 {
    font-size: 20px;
  }
  .joinUs h3 {
    font-size: 17px;
  }
  .streetGraphic {
    height: 60px;
  }
  .lists h1 {
    font-size: 17px;
  }
}

@media screen and (max-width: 360px) {
  .adminAbout h1 {
    font-size: 80%;
  }
  .adminAbout h2 {
    font-size: 80%;
  }
  .adminAbout h3 {
    font-size: 80%;
  }
  .streetGraphic {
    height: 50px;
  }
}

@media screen and (max-width: 310px) {
  .adminAbout h1 {
    font-size: 70%;
  }
  .adminAbout h2 {
    font-size: 70%;
  }
  .adminAbout h3 {
    font-size: 70%;
  }
  .adminMessage ul {
    padding-left: 15px;
  }
  .adminImage {
    height: 150px;
  }
  .joinUs h1 {
    margin-top: 5px;
    font-size: 14px;
  }
  .joinUs h3 {
    font-size: 12px;
  }
  .streetGraphic {
    height: 45px;
  }
}

@media screen and (max-width: 260px) {
  .streetGraphic {
    display: none;
  }
  .adminMessage p {
    font-size: 8px;
  }
}
