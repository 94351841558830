.page {
  color: white;
  text-align: left;
  margin: 150px;
}

.page h1 {
  font-family: "Oswald", sans-serif;
}

.page a {
  width: 50%;
  margin: 25px;
  text-decoration: none;
}

.currentNotices {
  width: 70%;
  margin-block: 50px;
}

.currentNotices a {
  font-size: 25px;
}

.prevNotices {
  width: 50%;
}

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.center {
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .page a {
    width: 95%;
  }
  .currentNotices {
    width: 95%;
  }
  .prevNotices {
    width: 95%;
  }
}

@media screen and (max-width: 850px) {
  .page {
    margin: 50px;
  }
}

@media screen and (max-width: 580px) {
    .page h1 {
        font-size: 20px;
    }

    .page a {
        margin: 15px;
    }

  .currentNotices a{
    font-size: 18px;
  }
}

@media screen and (max-width: 380px) {
    .page {
        margin: 15px;
    }

    .currentNotices a {
        font-size: 15px;
    }

}
