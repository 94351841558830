.footer {
  display: flex;
  position:relative;
  bottom: 0;
  width: 100%;
  height: fit-content;
  font-family: "Oswald", sans-serif;
  background-color: #000;
  margin-top: auto;
}

.footer h2 {
  font-family: "Open Sans", sans-serif;
  font-size: .5rem;
  text-align: left;
  margin: 2px;
  color: #454545;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  width: 100%;
}

.pageSelections {
  display: flex;
  margin-inline-end: 20px;
  margin-bottom: 0;
}

.nav {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin: 1rem;
  color: #ffffff;
  text-decoration: none;
}

.nav:hover {
  color: #ffa559;
  text-decoration: underline;
}

.socials {
  display: flex;
  align-items: center;
  width: 25%;
  justify-content: space-around;
  margin-right: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.icon {
  height: 2rem;
  color: white;
}

.icon:hover {
  color: #ffa559;
  scale: 1.1;
}

@media screen and (max-width: 1100px) {
  .logo {
    height: 50%;
    width: auto;
    margin-top: 30%;
  }

  .socials {
    width: 75%;
  }

  .footer h2 {
    margin-top: 25%;
  }

  .wrapper {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }
  .socials {
    width: 30%;
  }

}

@media screen and (max-width: 540px) {

  .logo {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .nav {
    margin-top: 3px;
    margin-bottom: 5px;
    margin-inline: 0.4rem;
    font-size: .65rem;
  }

  .socials {
    margin-block: 15px;
  }

  .icon {
    height: 1rem;
  }

  .footer h2 {
    margin-top: 20px;
  }
}

@media screen and (max-width: 280px) {
  .nav {
    font-size: 0.5rem;
  }

  .logo {
    height: 50%;
  }

  .footer h2 {
    font-size: 8px;
  }

  .nav {
    margin-top:10px;
    margin-bottom: 0;
  }

  .wrapper {
    margin-left: 5px;
  }

  .socials {
    height: 1rem;
    margin: 0;
  }

  .icon {
    height: .65rem;
  }
}
