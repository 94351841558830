.bgLinear {
  background: linear-gradient(180deg, #000000, #454545);
}

.bgLinearRev {
  background: linear-gradient(0deg, #000000, #454545);
}

.bgOrange {
  background-color: #ffa559;
}

.centerAlign {
  justify-items: center;
}

.centerAlign h1 {
  font-family: 'Oswald', sans-serif;
  color: white;
}

.title {
  color: white;
  padding-bottom: 10px;
}
.title h1 {
  padding-top: 100px;
  font-family: 'Oswald', sans-serif;
}

.title p {
  font-family: "Open Sans", sans-serif;
  font-size: 25px;
  margin-inline: 50px;
  text-align: left;
}

.links {
  padding: 50px;
  display: flex;
  justify-content: space-around;
}

.table {
  display: flex;
  justify-content: center;
  align-items: baseline;
  min-height: 400px;
}

.table table {
  color: white;
  padding-top: 10px;
  width: 70%;
  table-layout: fixed;
}

.table th {
  font-family: "Oswald", sans-serif;
  font-size: 30px;
  padding: 50px;
  text-decoration: underline;
}

.table tr {
  font-family: "Open Sans", sans-serif;
  font-size: 25px;
}

.table td {
  text-align: center;
  padding: 15px;
}

.table a {
  color: white;
}

.grid {
  display: grid;
  min-height: 200px;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  align-items: center;
  justify-items: center;
}


@media screen and (max-width: 850px) {
  .title h1 {
    padding-top: 20px;
    font-size: 25px;
  }
  .title p {
    font-size: 20px;
  }
  .table th {
    font-size: 25px;
    padding: 0;
  }
  .table tr {
    font-size: 20px;
    padding: 0;
  }
  .table td {
    padding: 0;
  }
}

@media screen and (max-width: 725px) {
  .title h1 {
    font-size: 23px;
  }
  .title p {
    text-align: center;
  }
  .links {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  .links button {
    margin: 10px;
  }
  .table th {
    font-size: 23px;
  }
  .table tr {
    font-size: 18px;
  }
  .centerAlign h1 {
    font-size: 25px;
  }
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 575px) {
  .title h1 {
    font-size: 20px;
  }
  .title p {
    font-size: 17px;
  }
  .table th {
    font-size: 20px;
  }
  .table tr {
    font-size: 17px;
  }
  .pdfIcon {
    display: none;
  }
}

@media screen and (max-width: 440px) {
  .title h1 {
    font-size: 18px;
  }
  .title p {
    font-size: 15px;
    margin-inline: 10px;
  }
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .table {
    min-height: 200px;
  }
  .table th {
    font-size: 18px;
  }
  .table tr {
    font-size: 15px;
  }
}
@media screen and (max-width: 370px) {
  .table table {
    width: 95%;
  }
}
