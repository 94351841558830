.page {
  color: white;
}

.page h2 {
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  margin: 0;
}

.page h3 {
  font-family: "Oswald", sans-serif;
  font-weight: bold;
}

.page h4 {
  margin: 0;
  font-family: "Oswald", sans-serif;
}

.grid {
  display: inline-grid;
  /* gap: 10px; */
}
.grid * {
  margin-inline: 10px;
}

.flex {
  display: flex;
}

.center {
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 50em) {
  .grid {
    grid-template-columns: auto auto auto;
  }
  .page {
    padding-inline: 30px;
  }
}
@media screen and (min-width: 80em) {
  .grid {
    grid-template-columns: auto auto auto auto;
  }
}
