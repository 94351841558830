.page {
  color: white;
}

.page h1 {
  font-family: "Oswald", sans-serif;
  font-weight: bold;
}

.page h2 {
  font-family: "Oswald", sans-serif;
}

.page h3 {
  font-family: "Oswald", sans-serif;
  font-weight: bold;
}

.reservationNotice {
  font-size: 20px;
}

.reservationNotice a {
  font-size: 20px;
}

.flex {
  display: flex;
}

.center {
  justify-content: center;
  align-items: center;
}

.leftAlign {
  text-align: left;
}

.table th,
td {
  padding-inline: 10px;
  font-size: 12px;
}

.backgroundOrange {
  background-color: #ffa559;
}

.borderOrange {
  border-radius: 12px;
  border: solid 2px #ffa559;
}

.section {
  margin-top: 1em;
  padding-top: 5px;
  padding-bottom: 50px;
  padding-inline: 10px;
}

.detailLists {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.detailLists ul li {
  font-family: "Open Sans", sans-serif;
  font-weight: 100;
  text-align: left;
}

.detailLists h4 {
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  font-weight: 100;
  margin: 0;
}

.infoCard {
  display: block;
  margin: 5px;
}

.infoCard * {
  margin: 5px;
  padding-inline: 5px;
  text-align: left;
}

.headingBackground {
  border-radius: 5px;
}

.disclaimer {
  font-family: "Oswald", sans-serif;
  font-size: 10px;
}

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox input {
  height: 20px;
  width: 20px;
  accent-color: #ffa559;
  border-radius: 50%;
}

.checkbox label {
  margin-inline: 20px;
}

@media screen and (min-width: 40em) {
  .infoCard {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .disclaimer {
    font-size: 13px;
  }

  .reservationNotice {
    font-size: 25px;
  }

  .reservationNotice a {
    font-size: 25px;
  }

  .table th,
  td {
    padding-inline: 20px;
    font-size: 15px;
  }
}

@media screen and (min-width: 80em) {
  .infoCard {
    grid-template-columns: repeat(4, 1fr);
  }

  .disclaimer {
    font-size: 15px;
  }

  .reservationNotice {
    font-size: 30px;
  }

  .reservationNotice a {
    font-size: 30px;
  }

  .detailLists {
    flex-direction: row;
    justify-content: center;
  }

  .detailLists ul {
    margin-right: 80px;
  }
}
