.page {
  background: linear-gradient(180deg, #000000 25%, #454545);
  padding-left: 3%;
  padding-right: 3%;
  color: white;
  font-family: "Open Sans", sans-serif;
}

.page input,
select,
span {
  padding-block: 2px;
  height: 35px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-radius: 5px;
  border: none;
}

.page input[type="text"] {
  margin: 10px;
}

.page input[type="number"] {
  margin: 10px;
}

input[type="file"] {
  display: none;
}

.customUpload{
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: white;
  color: #454545;
  border-radius: 5px;
  margin-bottom: 20px;
}

.customUpload:hover {
  scale: 1.1;
}

.page textarea {
  margin: 10px;
  padding-block: 2px;
  height: 100px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-radius: 5px;
  border: none;
  font-family: "Open Sans", sans-serif;
}

.page textarea::placeholder {
  font-family: "Open Sans", sans-serif;
}

.page h1 {
  font-family: "Oswald", sans-serif;
}

.page h2 {
  font-weight: 500;
  font-size: 25px;
}

.page h3 {
  font-weight: 300;
  font-size: 25px;
  color: #ffa559;
}

.page h4 {
  font-weight: 300;
  font-size: 18px;
  margin: 5px;
}

.page p,
a {
  color: white;
  font-weight: 300;
  font-size: 20px;
}

.page th {
  padding: 2%;
}

.page span {
  font-family: "Open Sans", sans-serif;
  font-size: 23px;
  font-weight: 200;
}

.page label {
  text-align: left;
}

.full {
  width: 91.5%;
}

.half {
  width: 45%;
}

.invalidInput {
  background-color: #ffcbcb;
}

.disabled {
  opacity: 0.8;
  pointer-events: none;

  z-index: 0;
}

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox input {
  height: 20px;
  width: 20px;
  accent-color: #ffa559;
  border-radius: 50%;
}

.checkbox label {
  margin-inline: 20px;
}

.confirmWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirm {
  text-align: left;
  width: 50%;
  background-color: white;
  border-radius: 12px;
  margin-bottom: 100px;
  padding: 25px;
  box-shadow: 5px 5px 7px #1e1e1e;
}

.confirm p {
  color: black;
}

.flex {
  display: flex;
}

.leftAlign {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.centerAlign {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stripeInput {
  border: none;
  background-color: white;
  height: 25px;
  border-radius: 7px;
  margin: 0;
  padding: 10px 5px 1px 5px;
}

.stripeInput div {
  background-color: transparent;
}

@media screen and (max-width: 700px) {
  .full {
    width: 90%;
  }
  .half {
    width: 90%;
  }
  .confirm {
    width: 90%;
  }
}

@media screen and (max-width: 580px) {
  .page input[type="text"] {
    height: 30px;
  }

  .page input[type="number"] {
    height: 30px;
  }

  .page select {
    height: 30px;
  }

  .page label {
    font-size: 17px;
  }

  .page h1 {
    font-size: 25px;
  }

  .page h2 {
    font-size: 20px;
  }

  .page h3 {
    font-size: 25px;
  }

  .page p,
  a,
  th,
  td {
    font-size: 15px;
  }

  .flex {
    flex-direction: column;
  }

  .confirm h3 {
    font-size: 25px;
  }
}

@media screen and (max-width: 345px) {
  .page h1 {
    font-size: 20px;
  }

  .page h2 {
    font-size: 17px;
  }

  .page h3 {
    font-size: 17px;
  }

  .page h4 {
    font-size: 15px;
  }

  .page p,
  a,
  th,
  td {
    font-size: 12px;
  }

  .page label {
    font-size: 13px;
  }

  .confirm {
    width: 95%;
    padding-inline: 3px;
  }
}
