.form {
  display: grid;
  gap: 1em;
  margin-inline: 2em;
}

.gridContainer {
  display: grid;
  gap: 1em;
}

.gridTwoCol {
  grid-template-columns: repeat(2, 1fr);
}

.gridThreeCol {
  grid-template-columns: repeat(3, 1fr);
}

.gridFourCol {
  grid-template-columns: repeat(4, 1fr);
}

.gridFiveCol {
  grid-template-columns: repeat(5, 1fr);
}

.gridSixCol {
  grid-template-columns: repeat(6, 1fr);
}

.gridSevenCol {
  grid-template-columns: repeat(7, 1fr);
}

.gridContainer *,
.customInput {
  border: none;
  border-radius: 5px;
  height: 2em;
  padding: 0;
  padding-left: 10px;
  min-width: 1em;
}

.gridSpanOne {
  grid-column: span 1;
}

.gridSpanTwo {
  grid-column: span 2;
}

.gridSpanThree {
  grid-column: span 3;
}

.gridSpanFour {
  grid-column: span 4;
}

.gridOne {
  grid-column: 1;
}

.gridTwo {
  grid-column: 2;
}

.gridThree {
  grid-column: 3;
}

.gridFour {
  grid-column: 4;
}

.invalid {
  background-color: #ffcbcb;
}

.invalid::placeholder {
  color: #454545;
}

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox input {
  height: 20px;
  width: 20px;
  accent-color: #ffa559;
  border-radius: 50%;
  margin-top: 5px;
}

.checkbox label {
  margin-inline: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 80em) {
  .form {
    margin-inline: 15em;
  }
}

@media screen and (min-width: 50em) {
  .form {
    margin-inline: 5em;
    gap: 2em;
  }

  .gridContainer {
    gap: 2em;
  }
  .gridContainer * {
    height: 2rem;
  }
}

@media screen and (max-width: 580px) {
  .gridSevenCol,
  .gridFiveCol,
  .gridSixCol {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 430px) {
  .gridContainer {
    display: block;
  }

  .gridContainer * {
    margin-block-start: 1em;
    width: 95%;
  }

  .checkbox {
    flex-direction: column;
  }
}
