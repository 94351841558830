.orderSummary {
  color: white;
  margin-inline: 2em;
}

.orderSummary h1 {
  font-family: "Oswald", sans-serif;
  text-align: left;
  font-size: 20px;
}

.orderSummary h2 {
    font-size: 18px;
}

.orderSummary h4 {
    font-size: 12px;
}

.summaryTable {
  text-align: left;
  width: 100%;
  color: white;
}

.summaryTable p {
  font-family: "Open Sans", sans-serif;
  font-weight: 200;
}

.summaryTable th {
    padding-inline: 1em;
}

.centerHorizontal {
  display: flex;
  justify-content: center;
}

.pageAlign {
  margin-inline: 5em;
}

.enterInv {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 1em;
  padding-bottom: 1em;
  max-width: 25em;
  background-color: #fff;
  margin-inline: 2em;
  border-radius: 12px;
}

.enterInv input {
  width: 80%;
  border: solid;
  border-color: #454545;
  border-radius: 5px;
}

.enterInv label {
  color: #454545;
  font-family: "Oswald", sans-serif;
}

.closeBtn {
  width: 95%;
  text-align: right;
  margin-top: 1em;
  margin-right: 1em;
}

@media screen and (min-width: 80em) {
  .pageAlign {
    margin-inline: 2em;
  }

  .summaryTable {
    width: 65%;
  }
}

@media screen and (min-width: 50em) {
  .pageAlign {
    margin-inline: 5em;
  }

  .orderSummary {
    margin-inline: 5em;
  }

  .orderSummary h1 {
    font-size: 25px;
  }
  
  .orderSummary h2 {
      font-size: 20px;
  }
  
  .orderSummary h4 {
      font-size: 15px;
  }

  .summaryTable {
    width: 45%;
  }
}
