.header {
  display: flex;
  justify-content: space-between;
  background-color: #000000;
  font-family: "Oswald", sans-serif;
  font-weight: 100;
  width: 100%;
}

.navActive {
  margin-inline: 1rem;
  font-size: 1.25rem;
  color: #ffa559;
  text-decoration: none;
}

.navInactive {
  /* margin-inline: 1rem; */
  margin-inline: 10px;
  font-size: 1.25rem;
  color: white;
  text-decoration: none;
}

.navActive:hover,
.navInactive:hover {
  text-decoration: underline;
  font-weight: 400;
}

.pageSelections {
  align-items: center;
  margin-right: 0;
  width: 25%;
}

.menuIcon {
  display: flex;
  align-items: center;
  margin-inline: 1rem;
  z-index: 2;
}

.hamburgerIcon {
  color: white;
}

.hamburgerIcon:hover {
  color: #ffa559;
  scale: 1.1;
}

.menuIcon button {
  background-color: transparent;
  border: none;
}

@media screen and (max-width: 1100px) {
  .pageSelections a {
    font-size: 110%;
  }
  .pageSelections {
    margin-right: 5%;
  }
}

@media screen and (max-width: 920px) {
  .pageSelections {
    margin-right: 10%;
  }
}

@media screen and (max-width: 800px) {
  .pageSelections {
    margin-right: 15%;
  }
}

@media screen and (max-width: 700px) {
  .pageSelections {
    display: none;
  }
}

@media screen and (max-width: 540px) {
  .navActive {
    margin-inline: 0.4rem;
    font-size: 1rem;
  }
  .navInactive {
    margin-inline: 0.4rem;
    font-size: 1rem;
  }
}

@media screen and (max-width: 280px) {
  .navActive {
    margin-inline: 0.5rem;
    font-size: 0.75rem;
  }
  .navInactive {
    margin-inline: 0.5rem;
    font-size: 0.75rem;
  }

  .pageSelections {
    margin-right: 1px;
  }
}
