.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: #000000;
  padding-top: 30px;
}

.header h1 {
  color: white;
  font-family: "Oswald", sans-serif;
}

.logo {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.logo svg {
  height: 50px;
  width: auto;
}

.socials {
  display: flex;
  position: absolute;
  right: 10px;
  top: 10%;
  width: 20%;
  margin-right: 5%;
  justify-content: space-around;
}

.icon {
  height: 2rem;
  color: white;
}

.icon:hover {
  color: #ffa559;
  scale: 1.2;
}

@media screen and (max-width: 1100px) {
  .logo {
    height: 6rem;
  }

  .socials {
    top: 7%;
    width: 30%;
    margin-right: 1px;
  }
}

@media screen and (max-width: 700px) {
  .socials {
    flex-direction: column;
    top: 2%;
  }
  .icon {
    margin: 5px;
  }
}

@media screen and (max-width: 580px) {
  .logo {
    height: 4rem;
  }

  .socials {
    flex-direction: column;
    align-items: flex-end;
    margin-right: 20px;
    top: 2%;
  }
  .icon {
    height: 1rem;
    margin: 5px;
    width: fit-content;
  }
  .socials svg {
    width: auto;
  }
}

@media screen and (max-width: 350px) {
  .header {
    padding-top: 0;
  }
}

@media screen and (max-width: 280px) {
  .socials {
    margin-right: 1%;
  }
  .icon {
    height: 12px;
  }
}
